.swap-modal {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    color: white;
    border: 3px solid white;
    border-top: unset;
    z-index: 2;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
}

.swap-title {
    margin-top: 0.8rem;
    font-size: 1.2rem;
    flex: 1;
    text-align: center;
}

.swap-selection-container {
    flex: 3;
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
    overflow-y: auto;
}

    .swap-selection.one {
        grid-row: 1 / 2;
        grid-column: 1 / 2;
    }

    .swap-selection.two {
        grid-row: 1 / 2;
        grid-column: 2 / 3;
    }

    .swap-selection {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
    }

    .ss-type {
        align-self: center;
        text-align: center;
        border-bottom: 2px solid white;
        padding-bottom: 5px;
        width: 50%;
    }

    .ss-content {
        margin-top: 5px;
        padding: 0px 10px 0px 10px;
        text-align: center;
        word-wrap: break-word;
        text-overflow: ellipsis;
        overflow-y: auto;
    }

.swap-button-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

    .swap-button {
        cursor: pointer;
        height: 1.3rem;
        fill: white;
        transition: fill 0.5s, border-color 0.5s;
        border: 2px solid white;
        border-radius: 50px;
    }

    .swap-button:hover {
        fill: red;
        border-color: red;
    }