.download-modal {
    position: absolute;
    width: 100%;
    max-width: 400px;
    height: 25%;
    min-height: 175px;
    z-index: 1;
    display: flex;
    justify-content: center;
}

.download-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 3px solid white;
    border-top: unset;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
}

.array-container {
    flex: 3;
    overflow-y: auto;
    padding: 5px;
    margin-top: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    width: 80%;
}

    .array-output {
        width: 100%;
        height: 100%;
        word-wrap: break-word;
    }

.download-button-container {
    flex: 1;
    width: 80%;
    padding: 0px 5px 0px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

    .download-button {
        cursor: pointer;
        height: 1.3rem;
        fill: white;
        transition: fill 0.5s, border-color 0.5s;
        border: 2px solid white;
        border-radius: 50px;
    }

    .download-button:hover {
        fill: red;
        border-color: red;
    }

.copy-notice {
    position: absolute;
    bottom: -1rem;
    opacity: 0;
    color: red;
}