.home-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.title {
    font-size: 2rem;
    cursor: default;
    user-select: none;
    color: white;
    margin-top: 1rem;
    text-align: center;
    top: 0;
    width: fit-content;
    border-bottom: 4px solid white;
    z-index: 2;
}

.h-buttons {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    max-width: 400px;
    min-width: fit-content;
    height: 3rem;
    z-index: 2;
}

.h-button {
    height: 2rem;
    padding: 5px;
    cursor: pointer;
    fill: white;
    transition: margin-bottom 0.5s, fill 0.5s;
}

.h-button.selected {
    fill: red;
    margin-bottom: 2rem;
}

.h-button:hover {
    fill: red;
}