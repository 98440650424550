.add-modal {
    position: absolute;
    width: 100%;
    max-width: 400px;
    min-height: 175px;
    height: 25%;
    z-index: 1;
}

.selection-container {
    position: absolute;
    border: 3px solid white;
    border-top: unset;
    box-sizing: border-box;
    z-index: 2;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

.selection-container.error {
    box-shadow: 0px 0.1px 10px;
}

.type-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

    .type-button:nth-child(1) {
        border-top: unset;
        border-left: unset;
    }

    .type-button:nth-child(2) {
        border-top: unset;
        border-right: unset;
    }

    .type-button:nth-child(3) {
        border-left: unset;
    }
    .type-button:nth-child(4) {
        border-right: unset;
    }
    .type-button:nth-child(5) {
        border-left: unset;
        border-bottom: unset;
    }
    .type-button:nth-child(6) {
        border-right: unset;
        border-bottom: unset;
    }

    .type-button {
        border: 2px solid white;
        color: white;
        font-weight: 700;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .type-button:hover {
        cursor: pointer;
    }

.input-container {
    position: absolute;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    color: white;
}

    .input-data-container {
        flex: 3;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

        .input-title {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .warning-container {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80%;
            font-size: 0.85rem;
            font-weight: 700;
            margin-bottom: 5px;
        }

        .input-box {
            flex: 3;
            font-size: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 80%;
        }

            .input-box textarea {
                box-sizing: border-box;
                font-size: inherit;
                min-width: 200px;
                padding: 5px;
                height: 100%;
                width: 100%;
                resize: none;
            }

            .number-input {
                font-size: inherit;
                height: 20%;
                width: 80%;
                padding: 5px;
            }

            .bool-button {
                cursor: pointer;
                font-size: inherit;
                font-weight: 700;
                width: 25%;
                height: 25%;
                margin: 10%;
                border: 3px solid white;
                opacity: 0.5;
                color: white;
                border-radius: 10px;
                background-color: unset;
                transition: opacity 0.2s;
            }

            .bool-button:hover {
                opacity: 1;
            }

            .bool-button.selected {
                opacity: 1;
            }

    .input-button-container {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

        .input-button {
            cursor: pointer;
            height: 1.3rem;
            fill: white;
            transition: fill 0.5s, border-color 0.5s;
            border: 2px solid white;
            border-radius: 50px;
        }

        .input-button:hover {
            fill: unset;
            border-color: black;
        }