.upload-modal {
    position: absolute;
    background-color: rgba(119, 136, 153, 0.4);
    width: 100%;
    max-width: 400px;
    height: 30%;
    min-height: 175px;
    z-index: 1;
}

.upload-container {
    position: absolute;
    box-sizing: border-box;
    border: 3px solid white;
    border-top: unset;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3.8rem;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 2;
}

.upload-container.error {
    box-shadow: 0px 0.1px 10px;
}

.upload-warning-container {
    color: white;
    text-align: center;
    flex: 0.5;
}

.upload-warning-container a {
    color: red;
}

.upload-error-container {
    text-align: center;
    flex: 0.5;
}

.upload-input {
    flex: 2;
    resize: none;
    width: 80%;
    min-width: 200px;
    height: 100%;
    margin-top: 0.2rem;
    padding: 5px;
}

.upload-button-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

    .upload-button {
        cursor: pointer;
        height: 1.3rem;
        fill: white;
        transition: fill 0.5s, border-color, 0.5s;
        border: 2px solid white;
        border-radius: 50px;
    }

    .upload-button:hover {
        fill: red;
        border-color: red;
    }