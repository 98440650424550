.editor-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.editor-modal {
    background-color: rgba(119, 136, 153, 0.4);
}

.e-buttons {
    position: absolute;
    bottom: 1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 95%;
    max-width: 500px;
    min-width: fit-content;
    height: 3rem;
    z-index: 1;
}

.e-button {
    height: 2rem;
    cursor: pointer;
    fill: white;
    transition: margin-bottom 0.5s, fill 0.5s;
}

.e-button.selected {
    fill: red;
    margin-bottom: 2rem;
}

.e-button:hover {
    fill: red;
}

.Array {
    background-color: darkgrey;
}

.Object {
    background-color: cornflowerblue;
}

.String {
    background-color: seagreen;
}

.Boolean {
    background-color: crimson;
}

.Number {
    background-color: salmon;
}

.Null {
    background-color: dimgray;
}