.delete-modal {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
    z-index: 2;
    overflow-x: hidden;
    overflow-y: hidden;
    color: white;
    border: 3px solid white;
    box-sizing: border-box;
    border-top: unset;
    transition: background-color 0.5s;
}

.delete-title {
    margin-top: 0.8rem;
    font-size: 1.2rem;
    display: flex;
    flex: 1;
    justify-content: center;
    text-align: center;
}

.delete-selection-container {
    flex: 3;
    color: white;
    width: 90%;
    height: auto;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

    .element-type {
        border-bottom: 2px solid white;
        padding-bottom: 5px;
        text-align: center;
    }

    .element-content {
        margin-top: 5px;
        overflow-y: auto;
        padding-right: 5px;
        word-wrap: break-word;
    }

.delete-button-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

    .delete-button {
        cursor: pointer;
        height: 1.3rem;
        fill: white;
        transition: fill 0.5s, border-color 0.5s;
        border: 2px solid white;
        border-radius: 50px;
    }

    .delete-button:hover {
        fill: unset;
        border-color: black;
    }