* {
  margin: 0;
  padding: 0;
  box-sizing: content-box;
  font-family:Consolas, 'Lucida Console', 'DejaVu Sans Mono', monospace;
}

html,
body,
#root,
.app,
canvas {
  width: 100%;
  height: 100%;
  overflow: hidden;
  
}

.canvas-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 0;
}

.canvas-container.hovered {
  cursor: pointer;
}